function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/';
const ROOTS_DASHBOARD = '/';

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, 'login'),
  loginUnprotected: path(ROOTS_AUTH, 'login-unprotected'),
  resetPassword: path(ROOTS_AUTH, 'reset-password'),
  setPassword: path(ROOTS_AUTH, 'set-password/:email/:token'),
  privacy: path(ROOTS_AUTH, 'privacy/:language'),
  accountDeletion: path(ROOTS_AUTH, 'delete-account-instructions'),
  termsOfUse: path(ROOTS_AUTH, 'terms-of-use'),
  getMobileApp: path(ROOTS_AUTH, 'get-mobile-app'),
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  user: {
    root: path(ROOTS_DASHBOARD, 'profile'),
  },
  users: {
    root: path(ROOTS_DASHBOARD, 'users'),
    new: path(ROOTS_DASHBOARD, 'users/new'),
  },
  projects: {
    root: path(ROOTS_DASHBOARD, 'projects'),
    new: path(ROOTS_DASHBOARD, 'projects/new'),
  },
  votes: {
    root: path(ROOTS_DASHBOARD, 'votes'),
    new: path(ROOTS_DASHBOARD, 'votes/new'),
    edit: (id) => path(ROOTS_DASHBOARD, `votes/${id}/edit`),
    voteResults: (id) => path(ROOTS_DASHBOARD, `votes/${id}/results`),
  },
  properties: {
    root: path(ROOTS_DASHBOARD, 'properties'),
    new: path(ROOTS_DASHBOARD, 'properties/new'),
  },
  residents: {
    root: path(ROOTS_DASHBOARD, 'residents'),
    new: path(ROOTS_DASHBOARD, 'residents/new'),
  },
  nonResidents: {
    root: path(ROOTS_DASHBOARD, 'non-residents'),
  },
  notifications: {
    root: path(ROOTS_DASHBOARD, 'notifications'),
  },
  issueTypes: {
    root: path(ROOTS_DASHBOARD, 'issue-types'),
    new: path(ROOTS_DASHBOARD, 'issue-types/new'),
  },
  posts: {
    root: path(ROOTS_DASHBOARD, 'posts'),
    new: path(ROOTS_DASHBOARD, 'posts/new'),
  },
  issues: {
    root: path(ROOTS_DASHBOARD, 'issues'),
  },
  callRequest: {
    root: path(ROOTS_DASHBOARD, 'call-requests'),
  },
  saleRealEstate: {
    root: path(ROOTS_DASHBOARD, 'real-estate'),
    new: path(ROOTS_DASHBOARD, 'real-estate/new'),
  },
  payments: {
    root: path(ROOTS_DASHBOARD, 'payments'),
  },
};
