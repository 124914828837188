import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import { PATH_AUTH } from 'routes/paths';

import ArcadaApiService from 'helpers/service/arcadaApiService';
import { fetchToken } from 'helpers/service/firebaseService';
import ChatSocket from 'helpers/service/chatSocketService';
import { AUTHENTICATED_USERS_NUMBERING } from 'helpers/constants/user';

import axios from 'utils/axios';
import { isValidToken, setSession } from 'utils/jwt';
import { getFormData } from 'utils/formData';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  UPDATE_USER: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  changePassword: () => Promise.resolve(),
  changeProfile: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  setPassword: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          // @TODO store user data in localStorage until an api call will be used to get user data on refresh
          // const response = await axios.get('/auth/me/');
          // const user = response.data;
          const user = JSON.parse(localStorage.getItem('user'));

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const response = await ArcadaApiService.auth.login(email, password);

    const { access, authenticatedUser: user } = response.data;
    if (!AUTHENTICATED_USERS_NUMBERING.includes(user.role)) {
      window.location.href = PATH_AUTH.login;
      return;
    }

    if (!user.push_notification_token) {
      fetchToken((pushNotificationToken) => changeProfile({ pushNotificationToken }));
    }

    if (user.role) setSession(access);
    // @TODO store user data in localStorage until an api call will be used to get user data on refresh
    localStorage.setItem('user', JSON.stringify(user));

    ChatSocket.getInstance();

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const forgotPassword = (email) => ArcadaApiService.user.resetPassword({ email });

  const setPassword = (password, email, token) =>
    ArcadaApiService.user.setPassword({
      password,
      email,
      activation_code: token,
    });

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register/', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const changePassword = async (data) => ArcadaApiService.user.changeProfile(getFormData(data));

  const changeProfile = async (data) => {
    const { data: user } = await ArcadaApiService.user.changeProfile(getFormData(data));

    if (user) {
      localStorage.setItem('user', JSON.stringify(user));

      dispatch({
        type: 'UPDATE_USER',
        payload: {
          isAuthenticated: true,
          user,
        },
      });
    }
  };

  const logout = async () => {
    const chatSocket = ChatSocket.getInstance();
    chatSocket.close();
    ChatSocket.resetClassInstance();

    await ArcadaApiService.auth.logout();
    setSession(null);
    dispatch({ type: 'LOGOUT' });
    // @TODO store user data in localStorage until an api call will be used to get user data on refresh
    localStorage.removeItem('user');
    localStorage.removeItem('accessToken');
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        changePassword,
        changeProfile,
        forgotPassword,
        setPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
