import { API_ENDPOINTS } from 'config';
import { ApiService } from '../apiService';

class User extends ApiService {
  changeProfile(data) {
    return this.patch(API_ENDPOINTS.user.profile(), data);
  }

  getUsers(params) {
    return this.get(API_ENDPOINTS.user.root, params);
  }

  getUser(id) {
    return this.get(`${API_ENDPOINTS.user.root}${id}/`);
  }

  addUser(data) {
    return this.post(API_ENDPOINTS.user.root, data);
  }

  editUser(data, id) {
    return this.patch(`${API_ENDPOINTS.user.root}${id}/`, data);
  }

  toggleUserStatus(data, id) {
    return this.patch(`${API_ENDPOINTS.user.root}${id}/`, data);
  }

  resetPassword(data) {
    return this.post(API_ENDPOINTS.user.resetPassword(), data);
  }

  setPassword(data) {
    return this.post(API_ENDPOINTS.user.setPassword(), data);
  }
}

export default new User();
