import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

import DashboardLayout from 'containers/layouts/dashboard';
import LogoOnlyLayout from 'containers/layouts/LogoOnlyLayout';

import LoadingScreen from 'components/LoadingScreen';

import GuestGuard from 'guards/GuestGuard';
import AuthGuard from 'guards/AuthGuard';
import RoleBasedGuard from 'guards/RoleBasedGuard';

import { USER_ROLES } from 'helpers/constants/user';

import { PATH_AFTER_LOGIN } from '../config';
import { PATH_AUTH, PATH_DASHBOARD } from './paths';

const Loadable = (Component) => (props) => {
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: PATH_AUTH.privacy,
      element: <Privacy />,
    },
    {
      path: PATH_AUTH.accountDeletion,
      element: <AccountDeletion />,
    },
      {
      path: PATH_AUTH.termsOfUse,
      element: <TermsOfUse />,
    },
    {
      path: PATH_AUTH.getMobileApp,
      element: <GetMobileApp />,
    },
    {
      path: PATH_AUTH.root,
      children: [
        {
          path: PATH_AUTH.login,
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: PATH_AUTH.resetPassword, element: <ResetPassword /> },
        { path: PATH_AUTH.setPassword, element: <SetPassword /> },
      ],
    },
    {
      path: PATH_DASHBOARD.root,
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: (
            <RoleBasedGuard
              accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN, USER_ROLES.SERVICE_COMPANY]}
            >
              <Navigate to={PATH_AFTER_LOGIN} replace />
            </RoleBasedGuard>
          ),
          index: true,
        },
        {
          path: PATH_DASHBOARD.residents.root,
          children: [
            {
              element: (
                <RoleBasedGuard
                  accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN, USER_ROLES.SERVICE_COMPANY]}
                >
                  <Resident />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: PATH_DASHBOARD.residents.new,
              element: (
                <RoleBasedGuard
                  accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN, USER_ROLES.SERVICE_COMPANY]}
                >
                  <ResidentFormPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN, USER_ROLES.SERVICE_COMPANY]}
                >
                  <ResidentFormPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        { path: PATH_DASHBOARD.user.root, element: <UserProfile /> },
        {
          path: PATH_DASHBOARD.projects.root,
          children: [
            {
              element: (
                <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN]}>
                  <Project />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: PATH_DASHBOARD.projects.new,
              element: (
                <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN]}>
                  <ProjectFormPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id',
              element: (
                <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN]}>
                  <ProjectFormPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: PATH_DASHBOARD.votes.root,
          children: [
            {
              element: (
                <RoleBasedGuard
                  accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN, USER_ROLES.SERVICE_COMPANY]}
                >
                  <Vote />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: PATH_DASHBOARD.votes.new,
              element: (
                <RoleBasedGuard
                  accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN, USER_ROLES.SERVICE_COMPANY]}
                >
                  <VotePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id/edit',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN, USER_ROLES.SERVICE_COMPANY]}
                >
                  <VotePage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id/results',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN, USER_ROLES.SERVICE_COMPANY]}
                >
                  <VotePage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: PATH_DASHBOARD.properties.root,
          children: [
            {
              element: (
                <RoleBasedGuard
                  accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN, USER_ROLES.SERVICE_COMPANY]}
                >
                  <Property />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: PATH_DASHBOARD.properties.new,
              element: (
                <RoleBasedGuard
                  accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN, USER_ROLES.SERVICE_COMPANY]}
                >
                  <PropertyFormPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN, USER_ROLES.SERVICE_COMPANY]}
                >
                  <PropertyFormPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: PATH_DASHBOARD.users.root,
          children: [
            {
              element: (
                <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN]}>
                  <User />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: PATH_DASHBOARD.users.new,
              element: (
                <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN]}>
                  <UserFormPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id',
              element: (
                <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN]}>
                  <UserFormPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: PATH_DASHBOARD.issueTypes.root,
          children: [
            {
              element: (
                <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN]}>
                  <IssueType />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: PATH_DASHBOARD.issueTypes.new,
              element: (
                <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN]}>
                  <IssueTypeFormPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id',
              element: (
                <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN]}>
                  <IssueTypeFormPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: PATH_DASHBOARD.posts.root,
          children: [
            {
              element: (
                <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN]}>
                  <Post />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: PATH_DASHBOARD.posts.new,
              element: (
                <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN]}>
                  <PostFormPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id',
              element: (
                <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN]}>
                  <PostFormPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: PATH_DASHBOARD.nonResidents.root,
          children: [
            {
              element: (
                <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN]}>
                  <NoResident />
                </RoleBasedGuard>
              ),
              index: true,
            },
          ],
        },
        {
          path: PATH_DASHBOARD.notifications.root,
          children: [
            {
              element: (
                <RoleBasedGuard
                  accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN, USER_ROLES.SERVICE_COMPANY]}
                >
                  <Notification />
                </RoleBasedGuard>
              ),
              index: true,
            },
          ],
        },
        {
          path: PATH_DASHBOARD.issues.root,
          children: [
            {
              element: (
                <RoleBasedGuard
                  accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN, USER_ROLES.SERVICE_COMPANY]}
                >
                  <Issue />
                </RoleBasedGuard>
              ),
              index: true,
            },
          ],
        },
        {
          path: PATH_DASHBOARD.callRequest.root,
          children: [
            {
              element: (
                <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN]}>
                  <CallRequest />
                </RoleBasedGuard>
              ),
              index: true,
            },
          ],
        },
        {
          path: PATH_DASHBOARD.saleRealEstate.root,
          children: [
            {
              element: (
                <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN]}>
                  <SaleRealEstate />
                </RoleBasedGuard>
              ),
              index: true,
            },
            {
              path: PATH_DASHBOARD.saleRealEstate.new,
              element: (
                <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN]}>
                  <SaleRealEstateFormPage />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':id',
              element: (
                <RoleBasedGuard accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN]}>
                  <SaleRealEstateFormPage />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: PATH_DASHBOARD.payments.root,
          children: [
            {
              element: (
                <RoleBasedGuard
                  accessibleRoles={[USER_ROLES.SUPER_ADMIN, USER_ROLES.MANAGING_ADMIN, USER_ROLES.SERVICE_COMPANY]}
                >
                  <Payments />
                </RoleBasedGuard>
              ),
              index: true,
            },
          ],
        },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        {
          path: '404',
          element: (
            <AuthGuard>
              <NotFound />
            </AuthGuard>
          ),
        },
        {
          path: '*',
          element: (
            <AuthGuard>
              <Navigate to="/404" replace />
            </AuthGuard>
          ),
        },
      ],
    },
    {
      path: '*',
      element: (
        <AuthGuard>
          <Navigate to="/404" replace />
        </AuthGuard>
      ),
    },
  ]);
}

const Login = Loadable(lazy(() => import('containers/pages/auth/Login')));
const Privacy = Loadable(lazy(() => import('containers/pages/privacy/Privacy')));
const AccountDeletion = Loadable(lazy(() => import('containers/pages/privacy/AccountDeletion')));
const TermsOfUse = Loadable(lazy(() => import('containers/pages/termsOfUse/TermsOfUse')));
const GetMobileApp = Loadable(lazy(() => import('containers/pages/getMobileApp/GetMobileApp')));
const ResetPassword = Loadable(lazy(() => import('containers/pages/auth/ResetPassword')));
const SetPassword = Loadable(lazy(() => import('containers/pages/auth/SetPassword')));

const Page500 = Loadable(lazy(() => import('containers/pages/Page500/Page500')));
const NotFound = Loadable(lazy(() => import('containers/pages/Page404/Page404')));

const Resident = Loadable(lazy(() => import('containers/pages/resident')));
const ResidentFormPage = Loadable(lazy(() => import('containers/pages/resident/residentManager/ResidentFormPage')));

const User = Loadable(lazy(() => import('containers/pages/user')));
const UserFormPage = Loadable(lazy(() => import('containers/pages/user/userManager/UserFormPage')));
const UserProfile = Loadable(lazy(() => import('containers/pages/user/userProfile')));

const Project = Loadable(lazy(() => import('containers/pages/project')));
const ProjectFormPage = Loadable(lazy(() => import('containers/pages/project/ProjectManager/ProjectFormPage')));

const Vote = Loadable(lazy(() => import('containers/pages/vote')));
const VotePage = Loadable(lazy(() => import('containers/pages/vote/voteManager/VotePage')));

const Property = Loadable(lazy(() => import('containers/pages/property')));
const PropertyFormPage = Loadable(lazy(() => import('containers/pages/property/propertyManager/PropertyFormPage')));

const IssueType = Loadable(lazy(() => import('containers/pages/issueType')));
const IssueTypeFormPage = Loadable(lazy(() => import('containers/pages/issueType/issueTypeManager/IssueTypeFormPage')));

const Post = Loadable(lazy(() => import('containers/pages/post')));
const PostFormPage = Loadable(lazy(() => import('containers/pages/post/postManager/PostFormPage')));

const NoResident = Loadable(lazy(() => import('containers/pages/noResident')));

const Notification = Loadable(lazy(() => import('containers/pages/notification')));

const Issue = Loadable(lazy(() => import('containers/pages/issue')));

const CallRequest = Loadable(lazy(() => import('containers/pages/callRequest')));

const SaleRealEstate = Loadable(lazy(() => import('containers/pages/saleRealEstate')));
const SaleRealEstateFormPage = Loadable(
  lazy(() => import('containers/pages/saleRealEstate/saleRealEstateManager/saleRealEstateFormPage'))
);

const Payments = Loadable(lazy(() => import('containers/pages/payments')));
