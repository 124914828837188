import { PATH_DASHBOARD } from './routes/paths';

export const HOST_API = `${process.env.REACT_APP_BASE_URL}/api` || '';

export const PATH_AFTER_LOGIN = PATH_DASHBOARD.residents.root;

export const HEADER = {
  MOBILE_HEIGHT: 55,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 55,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// Please remove `localStorage` when you set settings.

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeColorPresets: 'default',
  themeLayout: 'horizontal',
  themeStretch: true,
};

export const API_ENDPOINTS = {
  auth: {
    root: 'auth/',
    login() {
      return `${this.root}login/`;
    },
    logout() {
      return `${this.root}logout/`;
    },
    tokenRefresh() {
      return `${this.root}token/refresh/`;
    },
    tokenVerify() {
      return `${this.root}token/verify/`;
    },
  },
  user: {
    root: 'user/',
    profile() {
      return `${this.root}profile/`;
    },
    resident() {
      return `${this.root}resident/`;
    },
    addFamilyMember() {
      return `${this.resident()}add-family-member/`;
    },
    residentCsv() {
      return `${this.resident()}upload/`;
    },
    realProperty() {
      return `${this.resident()}real-property/`;
    },
    realPropertyBulk() {
      return `${this.realProperty()}bulk-update/`;
    },
    resetPassword() {
      return `${this.root}activate/reset-password/`;
    },
    setPassword() {
      return `${this.root}activate/`;
    },
  },
  project: {
    root: 'project/',
    structure() {
      return `${this.root}structure/`;
    },
    apartments() {
      return `${this.root}apartments/`;
    },
  },
  property: {
    root: 'real-property/',
    listOfProperties() {
      return `${this.root}all-list/`;
    },
    listOfAvailableProperties() {
      return `${this.root}available-list/`;
    },
  },
  vote: {
    root: 'poll/',
    voteQuestions(id) {
      return `${this.root}${id}/questions/`;
    },
    voteResults(id) {
      return `${this.root}${id}/results/`;
    },
    voteResultsExport(id) {
      return `${this.root}${id}/export/`;
    },
    voteParticipatedUsers(id) {
      return `${this.root}${id}/users-list/`;
    },
    voteQuestionUserAnswers(id) {
      return `${this.root}question/${id}/users/`;
    },
    voteQuestionAttachments() {
      return 'poll-question-attachment/';
    },
    sendVote(id) {
      return `${this.root}${id}/users/`;
    },
    deleteBulkVoteQuestionAttachments() {
      return `${this.voteQuestionAttachments()}bulk-delete/`;
    },
  },
  issueType: {
    root: 'issue-type/',
  },
  issue: {
    root: 'issue/',
    monthlyData: 'issue/monthly-data/',
  },
  chat: {
    root: 'chat/',
    room() {
      return `${this.root}room/`;
    },
    messageFile() {
      return `${this.root}message-file/`;
    },
  },
  post: {
    root: 'post/',
    attachments: {
      root: 'post-attachment/',
      bulkDeletePostAttachments() {
        return `${this.root}bulk-delete/`;
      },
    },
  },
  noResident: {
    root: 'user/non-resident/',
  },
  callRequest: {
    root: 'service/call-request/',
    count: 'service/call-request-count/',
  },
  notification: {
    root: 'notification/',
    receivers() {
      return `${this.root}receivers/`;
    },
  },
  payment: {
    root: 'payment/',
    transactionsAll() {
      return `${this.root}transactions/all/`;
    },
    transactionsDebts() {
      return `${this.root}transactions/debt/`;
    },
    transactionsPaid() {
      return `${this.root}transactions/paid/`;
    },
    paymentManual() {
      return `${this.root}card/pay-manual/`;
    },
    paymentSurcharge() {
      return `${this.root}card/pay-surcharge/`;
    },
    refundManual() {
      return `${this.root}card/refund-manual/`;
    },
    actions() {
      return `${this.root}actions/`;
    },
  },
  saleRealEstate: {
    root: 'service/real-estate/',
    saleRealEstateFile: {
      root: 'service/real-estate-file/',
      bulkDeleteSaleRealEstateFile() {
        return `${this.root}bulk-delete/`;
      },
    },
    saleRealEstateAttachment: {
      root: 'service/real-estate-attachment/',
      bulkDeleteSaleRealEstateAttachment() {
        return `${this.root}bulk-delete/`;
      },
    },
  },
};
